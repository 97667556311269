import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  Paper,
  Box,
  CircularProgress,
  Divider
} from '@mui/material';

import CrossFrame from "./crossFrame";

import Empty from './images/ic-empty-search-result.svg';

import useStore from './store';

import BackButton from './components/BackButton';
import SearchBar from './components/SearchBar';
import { calculateDistance } from './Utils';
import AndroidToast, { showAndroidToast } from './components/Toast';
import HighlightedText from './components/HighlightedText';
import { isAndroid, isIOS } from 'react-device-detect';

function AddressSearch() {
  const instanceRef = useRef({crossFrame: null});
  // const [address, setAddress] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // 로딩 상태를 관리하는 변수

  const initUrl = new URL(window.location.href);
  const queryString = new URLSearchParams(initUrl.search);
  const hub_lat = queryString.get('hub_lat');
  const hub_lng = queryString.get('hub_lng');
  const request = queryString.get('request');
  const favoriteList = queryString.get('favoritelist');
  const departure_lat = queryString.get('departure_lat');
  const departure_lng = queryString.get('departure_lng');
  const lat = queryString.get('lat');
  const lng = queryString.get('lng');
  const hostAddressName = queryString.get('hostAddressName');
  const [showNoResults, setShowNoResults] = useState(false);
  const [lastVisibleIndex, setLastVisibleIndex] = useState(null);
  const listRef = useRef();
  const listItemRefs = useRef([]);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const searchBarRef = useRef(null); // SearchBar에 대한 ref 생성

  const { setHubLat, setHubLng, setRequest, setSearchAddressName, setKeyword, keyword, setFavorites, setDepartureLat, setDepartureLng } = useStore();

  const onCrossFrameInitial = useCallback(() => {
    const instance = instanceRef.current;
    instance.crossFrame?.destroy();

    instance.crossFrame = null;
    instance.crossFrame = new CrossFrame();
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트된 이후에 input에 포커스를 줍니다.
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (hub_lat) {
      setHubLat(hub_lat);
    }
    if (hub_lng) {
      setHubLng(hub_lng);
    }
    if (request) {
      console.log('setRequest : ', request);
      setRequest(request);
    }

    if (departure_lat) {
      setDepartureLat(departure_lat);
    }

    if (departure_lng) {
      setDepartureLng(departure_lng);
    }


    // if (lat && lng && request ==='modify') {
    //   console.log('useEffect it is modify lat : ', lat, ' lng : ', lng);
    //   setTimeout(() => {
    //     navigate('/map', { state: { x: lng, y :lat, mapOnly : true } });
    //   }, 2000);
    //   // navigate('/map', { state: { x: lng, y :lat, mapOnly : true } });
    // }

  }, [departure_lat, departure_lng, hub_lat, hub_lng, request, setDepartureLat, setDepartureLng, setHubLat, setHubLng, setRequest]);

  useEffect(() => {
    console.log('setFavoriteList favoriteList :', favoriteList);
    if (favoriteList) {
      setFavorites(decodeURIComponent(favoriteList).split(','));
    } 
  }, [favoriteList, setFavorites]);

   // 로딩 화면을 1초간 보여주기 위한 useEffect
  //  useEffect(() => {
  //   if (lat && lng && request === 'modify') {
  //     setLoading(true);  // 로딩 시작
  //     console.log('useEffect it is modify lat : ', lat, ' lng : ', lng);
  //     setTimeout(() => {
  //       setLoading(false);  // 1초 후 로딩 종료
  //       navigate('/map', { replace: true, state: { x: lng, y: lat, mapOnly: true, hostAddressName } });
  //     }, 500);
  //   }
  // }, [lat, lng, request, navigate, hostAddressName]);

  useEffect(() => {
    console.log('addressSearch useEffect');
    setSearchAddressName('');
    onCrossFrameInitial();
  }, [onCrossFrameInitial, setSearchAddressName]);

  // 키워드 검색 URL
  const keywordUrl = 'https://dapi.kakao.com/v2/local/search/keyword.json';
  // 주소 검색
  const addressUrl = 'https://dapi.kakao.com/v2/local/search/address.json';
  const handleSearch = useCallback(async () => {
    console.log('handleSearch keyword : ', keyword)
    if (!keyword) {
      console.log('handleSearch keyword is empty');
      setSearchResults([]);
      setError('검색 결과가 없습니다.');
      return;
    }
    try {
      console.log('hub_lat : ', hub_lat, ' hub_lng :', hub_lng);
      const [keywordResponse, addressResponse] = await Promise.all([
        axios.get(keywordUrl, {
          headers: {
            Authorization: 'KakaoAK e638f175e56ecc1e8d826ef5713059f2',
          },
          params: {
            x: hub_lng,
            y: hub_lat,
            query: keyword,
          },
        }),
        axios.get(addressUrl, {
          headers: {
            Authorization: 'KakaoAK e638f175e56ecc1e8d826ef5713059f2',
          },
          params: {
            query: keyword,
          },
        })
      ]);

      // 두 개의 결과 합치기
      const keywordDocuments = keywordResponse.data.documents || [];
      const addressDocuments = addressResponse.data.documents || [];

      // 두 결과를 합침
      const combinedResults = [...keywordDocuments, ...addressDocuments];

      const prevSearchResult = searchResults;

      if (combinedResults.length > 0) {
        setSearchResults(combinedResults);
        setError(null);
      } else {
        if (keyword && prevSearchResult) { //검색도중에 빌 경우에 검색결과가 없다고 하니 추가.
          console.log('yuji search result');
          return;
        }
        setSearchResults([]);
        setError('검색 결과가 없습니다.');
      }
    } catch (error) {
      console.error('Error fetching keyword:', error);
      setSearchResults([]);
      setError('주소 검색 중 오류가 발생했습니다.');
    }
  }, [keyword, hub_lat, hub_lng, searchResults]);

  const handleSelect = (selectedPlace) => {
    const { place_name, x, y } = selectedPlace;
    setSearchAddressName(place_name);
    // navigate('/map', { state: { searchAddressName: place_name, x, y } });
    const tempHostAddressName = request === 'modify' ? hostAddressName : undefined;
    navigate('/map', { state: { x, y , hostAddressName : tempHostAddressName}, replace: true });
  };

  const handleSetting = (selectedPlace) => {
    const { place_name, x, y, road_address_name, address_name } = selectedPlace;
    const tempHostAddressName = request === 'modify' ? hostAddressName : undefined;
    const responseJson = {command: 'complete', response: request, lat: Number(y), lng: Number(x), addressName: place_name, originalAddressName: road_address_name || address_name, hostAddressName : tempHostAddressName };
    if (request === 'favorite') {

      //point1 => 허브 위치
      const point1 = { lat: hub_lat, lon: hub_lng };
      const point2 = { lat: responseJson.lat, lon: responseJson.lng };
      const distance = calculateDistance(point1, point2);
      console.log(`Distance: ${distance} meters`);

      if (distance < 100) {
        showAndroidToast('출발지와 가까워 장소를 추가할 수 없어요.');
        return;
      }

      navigate('/inputname', { state : responseJson, replace: true})
    } else {
      instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
        console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
      });
    }
    clearValue();
  };

  const clearValue = () => {
    // 상태 초기화
    setKeyword('');
    setSearchResults([]);
    setError(null);
  }

  const handleMapClick = () => {
    console.log('map clicked!');
    if (!hub_lat || !hub_lng) {
      alert('ThinQ 홈 위치가 전달되지 않아 사용할 수 없어요.');
    } else {
      //address_name 은 실제로 안쓰고 있음 map 에서
      navigate('/map', { state: { x : hub_lng, y : hub_lat }, replace: true });
    }

    //test code 
    // const hub_lat2 = "37.59658060059806";
    // const hub_lng2 = "126.87716767678005";
    
    // navigate('/map', { state: { address_name: 'temp', x : hub_lng2 , y :  hub_lat2} });

  };

  const handleScroll = () => {
    if (!listRef.current) return;

    // 현재 스크롤된 리스트 아이템 중 마지막으로 보이는 아이템의 인덱스를 추적
    let lastVisible = -1;
    listItemRefs.current.forEach((ref, index) => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const listRect = listRef.current.getBoundingClientRect();
        // 리스트 아이템의 하단이 리스트의 하단보다 위에 있을 때 마지막으로 보이는 것으로 처리
        if (rect.top >= listRect.top && rect.bottom <= listRect.bottom) {
          lastVisible = index;
        }
      }
    });
    setLastVisibleIndex(lastVisible);
  };

  useEffect(() => {
    // 스크롤 이벤트 등록
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (keyword.trim() !== '') {  // 공백 체크 추가
      console.log('keyword : ', keyword);
      handleSearch();
    } else {
      setSearchResults([]);  // 주소가 빈 경우 검색 결과 초기화
      setError(null);  // 오류 메시지 초기화
    }
      // handleSearch 를 의존성 배열에 넣으면 계속 불림 일단 막음
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const handleExit = useCallback(() => {
    console.log("handleExit");
    const responseJson = {command: 'exit'};
    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });
  }, []);

  // iOS 키보드 감지
  useEffect(() => {
    const handleFocusIn = () => {
      setIsKeyboardVisible(true);
    };

    const handleFocusOut = () => {
      setIsKeyboardVisible(false);
    };

    window.addEventListener('focusin', handleFocusIn);
    window.addEventListener('focusout', handleFocusOut);

    return () => {
      window.removeEventListener('focusin', handleFocusIn);
      window.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  useEffect(() => {
    if (keyword && searchResults.length === 0) {
      // 검색 결과가 없을 때 1초 후에 메시지를 보여줌
      const timer = setTimeout(() => {
        if (searchResults.length === 0) {
          setShowNoResults(true);
        }
      }, 1000); // 1000ms = 1초

      return () => clearTimeout(timer); // 컴포넌트가 unmount 될 때 타이머 정리
    } else {
      // 새로운 검색을 시작할 때 초기화
      setShowNoResults(false);
    }
  }, [keyword, searchResults]);

  return (
    <Container disableGutters style={{ paddingTop: '0rem', textAlign: 'center', marginLeft: 0, height: '100vh', overflow: loading ? 'hidden' : 'auto' }}>
      {/* 로딩 중일 때 CircularProgress 표시 */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" overflow="hidden">
          <CircularProgress />
        </Box>
      ) : (
        <>
        <Box 
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          textAlign="center">
          {/* <BackButton exitCallback={handleExit} /> */}
          <Typography variant="h6" gutterBottom width="100%" align="left" 
            sx={{
              fontSize: '17px',
              color: '#606c80'
            }}>
            {request === 'departure' ? "출발지" : "즐겨 찾는 장소"}
          </Typography>
          <SearchBar
            ref={searchBarRef} // ref 전달
            keyword={keyword}
            setKeyword={setKeyword}
            handleClear={() => setKeyword('')}
            handleMapClick={handleMapClick}
          />
          {/* {
            error || searchResults.length > 0 ? (
              <></>
            ) : (
              <Box sx={{ textAlign: 'right' }}>
                <MapOutlinedIcon
                  onClick={handleMapClick}
                  sx={{ cursor: 'pointer' }} // 클릭할 수 있도록 커서 변경
                />
              </Box>
            )
          } */}

          {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
          {searchResults.length > 0 && (
            <Paper elevation={3} style={{ marginTop: '1rem', borderRadius: '16px', width: '100%' }}>
              <List
                ref={listRef}
                onScroll={handleScroll}
                style={{
                  maxHeight: 'calc(100vh - 150px)', // 브라우저 하단과의 간격 유지
                  overflow: 'auto',
                  paddingTop:'0px',
                }}>
                {searchResults.map((result, index) => (
                  <React.Fragment key={index}>
                    <ListItem button key={index} onClick={() => handleSelect(result)}
                      ref={(el) => (listItemRefs.current[index] = el)}
                      disableRipple={true}  // 기본 Ripple 효과 제거 (원한다면 true로 설정)
                      sx={{
                        borderRadius: index === 0 ? '16px 16px 0 0' : index === lastVisibleIndex ? '0 0 16px 16px' : '0px', // 첫 번째와 마지막 아이템만 둥글게
                        minHeight:'83px',
                        '&:hover': {
                          backgroundColor: 'transparent',  // hover 시 효과를 명확히
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: 'transparent', // focus 시 배경색 제거
                        },
                        '&.Mui-selected': {
                          backgroundColor: 'transparent', // 클릭 후 선택된 배경색 제거
                          '&:hover': {
                            backgroundColor: 'transparent', // 선택된 후 hover 배경색 제거
                          },
                        },
                      }}>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" style={{ fontSize: '17px', marginLeft:'4px' }}>
                            <HighlightedText
                              text={result.place_name || result.road_address_name || result.address_name}
                              keyword={keyword}  // 사용자가 입력한 키워드
                            />
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" style={{ fontSize: '13px', marginLeft:'4px'}}>
                              {result.road_address_name || result.address_name}
                            </Typography>
                            {hub_lat && (
                              <Typography variant="caption" color="textSecondary" style={{ fontSize: '13px' , marginLeft:'4px'}}>
                                {result.distance
                                  ? (result.distance >= 1000
                                    ? `${(result.distance / 1000).toFixed(1)} km`
                                    : `${result.distance} m`)
                                  : ''}
                              </Typography>
                            )}
                          </>
                        }
                      />
                      {/* <Button variant="outlined" color="black" size="small" onClick={(e) => {
                        e.stopPropagation();
                        handleSetting(result);
                      }}>
                        설정
                      </Button> */}
                    </ListItem>
                    {index < searchResults.length - 1 && <Divider sx={{ borderColor: '#cad0dd', borderBottomWidth: 1, marginLeft: '20px', marginRight: '20px' }} />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          )}
          {
            keyword && showNoResults && (
              <>
                <Box 
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign="center"
                  style={{ height: isAndroid ? '100%' : isKeyboardVisible ? '35vh' : '100vh', paddingTop: '2rem', paddingBottom: '2rem' }}
                >
                  {/* 이미지와 텍스트를 동일한 Box에 배치 */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ gap: '10px' }}  // 이미지와 텍스트 사이의 간격 설정
                  >
                    {/* 이미지 */}
                    <img src={Empty} alt="No Results" style={{ width: '80px', height: '80px' }} />
                    {/* 텍스트 */}
                    <Typography variant="h6" style={{ fontSize: '16px'}}>
                      {`"${keyword}" 검색 결과가 없어요.`}
                    </Typography>
                  </Box>
                </Box>
              </>
            )
          }
          </Box>
        </>
      )}
      <AndroidToast/>
    </Container>
  );
}

export default AddressSearch;