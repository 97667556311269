import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import CrossFrame from "./crossFrame";
import BackButton from './components/BackButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useStore from './store';
import { calculateDistance } from './Utils';
import AndroidToast, { showAndroidToast } from './components/Toast';
import SearchIcon from './images/ic-search.svg';
import CustomMarkerImage from './images/ic-map-spot.svg';
import CustomDialog from './components/CustomDialog';
import { isAndroid, isIOS } from 'react-device-detect';

import {
  Button,
  Typography,
  Box
} from '@mui/material';

function MapApp() {
  const navigate = useNavigate();
  const instanceRef = useRef({crossFrame: null});
  const location = useLocation();
  const [x, setX] = useState(location.state?.x || null);
  const [y, setY] = useState(location.state?.y || null);
  const [move, setMove] = useState(false);
  const [hostAddressName, setHostAddressName] = useState(location.state?.hostAddressName || null);

  const { setMapX, setMapY, setRequest, request, mapX, mapY, searchAddressName, setSearchAddressName, hubLat, hubLng, departureLat, departureLng } = useStore();
  const [dialogProps, setDialogProps] = useState({ open: false, title: '', content: '', confirmText: '' });

  console.log('searchAddressName : ', searchAddressName);
  const [mapOnly, setMapOnly] = useState(location.state?.mapOnly || false);

  const [bottomAddressMain, setBottomAddressMain] = useState('');
  const [bottomAddressSub, setBottomAddressSub] = useState('');

  const [response, setResponse] = useState({});

  useEffect(() => {
    if (mapX) {
      setX(mapX);
      console.log('mapX is exist . setX : ', mapX);
    }

    if (mapY) {
      setY(mapY);
      console.log('mapY is exist . setMapY : ', mapY);
    }

  }, [setMapX, setMapY, x, y])

  const onCrossFrameInitial = () => {
    const instance = instanceRef.current;
    instance.crossFrame?.destroy();

    instance.crossFrame = null;
    instance.crossFrame = new CrossFrame();
    // instance.crossFrame
    //   .on(`alert`, handleAlert)
  }

  useEffect(() => {
    console.log('first loading..');
  }, []);

  useEffect(() => {

  }, []);

  const sendCrossFrameEvent = () => {
    console.log('[sendCrossFrameEvent] response : ', response);

    if (request === 'favorite' || request === 'modify') {

      //point1 => 출발지의 위치
      const point1 = { lat: departureLat, lon: departureLng };
      const point2 = { lat: response.lat, lon: response.lng };
      const distance = calculateDistance(point1, point2);
      console.log(`Distance: ${distance} meters`);

      if (distance < 100) {
        handleOpenDialog('경고', '출발지와 가까워 장소를 추가할 수 없어요.');
        return;
      }

      if (move) {
        setSearchAddressName(null);
      }

      //modify 를 완료할경우에 host 에 id 를 알려줘야하기 때문에 전달할수도 있게 전해줌.
      response.hostAddressName = location.state?.hostAddressName;

      navigate('/inputname', { state : response, replace: true });
    } else {
      instanceRef.current.crossFrame.emit('iframe_to_parent', response, function(err, result) {
        console.log('[crossFrame][click] iframe_to_parent callback', 'err:', err, 'result:', result);
      });
      // navigate(-1);
    }
  };

  // const getQueryParams = useCallback(() => {
  //   console.log('getQueryParams...');
  //   const searchParams = new URLSearchParams(location.search);
  //   const lat = parseFloat(searchParams.get('lat')) || 37.566826;  // 기본값: 서울
  //   const lng = parseFloat(searchParams.get('lng')) || 126.9786567; // 기본값: 서울
    


  //   //이게 뒤로가기시에 param 에 존재하는 값으로 변경해버림.
  //   // setMapX(lng);
  //   // setMapY(lat);

  //   // modify 로 처음들어올때 이게 없으면 지도 안나옴.
  //   // setX(lng);
  //   // setY(lat);
  //   /**
  //    * 위의 내용들을 주석하고 만족하는 코드가 아래임.
  //    */
  //   if (mapX) {
  //     setX(mapX);
  //   } else {
  //     setMapX(lng);
  //     setX(lng);
  //   }

  //   if (mapY) {
  //     setY(mapY);
  //   } else {
  //     setMapY(lat);
  //     setY(lat);
  //   }

  //   setRequest(searchParams.get('request'));
  //   setMapOnly(true);
  //   return { lat, lng };
  // }, [location.search]);

  const getAddressPriority = (addressData) => {
    if (!addressData) return "없음";

    const roadAddress = addressData.road_address;
    const address = addressData.address;

    // 1순위: road_address.building_name
    if (roadAddress && roadAddress.building_name) {
        return roadAddress.building_name;
    }
    // 2순위: road_address.address_name
    if (roadAddress && roadAddress.address_name) {
        return roadAddress.address_name;
    }
    // 3순위: address.address_name
    if (address && address.address_name) {
        return address.address_name;
    }

    // 값이 없을 경우 "없음" 반환
    return "없음";
  }

  const getAddressPriorityExcludeBuildingName = (addressData) => {
    if (!addressData) return "없음";

    const roadAddress = addressData.road_address;
    const address = addressData.address;

    // 1순위: road_address.address_name
    if (roadAddress && roadAddress.address_name) {
        return roadAddress.address_name;
    }
    // 2순위: address.address_name
    if (address && address.address_name) {
        return address.address_name;
    }

    // 값이 없을 경우 "없음" 반환
    return "없음";
  }

  const getAddressInformation = async (x, y) => {
      console.log('getAddressInformation x : ', x, ' y' , y);

      // 좌표로 주소 변환하기
      const url = 'https://dapi.kakao.com/v2/local/geo/coord2address.json';
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: 'KakaoAK e638f175e56ecc1e8d826ef5713059f2',
          },
          params: {
            x,
            y
          },
        });
  
        const { documents } = response.data;
        console.log('getAddressInformation search res : ', response.data);
  
        if (documents.length > 0) {
          console.log('document ', documents);
          return documents;
        } else {
          return [];
        }
      } catch (error) {
        console.error('Error fetching address:', error);
        return [];
      }
  };

  useEffect(() => {
    window.kakao.maps.load(async () => {
      onCrossFrameInitial();

      console.log('useEffect x = ', x, " y : ", y);

      //X,Y 없다는건 Map 에 바로 들어왔다는 뜻
      // if (!x || !y) {
      //   const query = getQueryParams();
      //   // setY(query.lat);
      //   // setX(query.lng);
      //   setMapOnly(true);
      //   return;
      // }

      // const { lat, lng } = { y, x }

      // console.log(' lat : ', lat , ' lng : ', lng)
      // if (!instanceRef.current.map) {
        const container = document.getElementById('map');
        console.log('[1] mapY : ', mapY, ' mapX : ', mapX, ' x : ', x, ' y:', y);
        const options = {
          center: new window.kakao.maps.LatLng(y, x),
          level: 3
        };
        instanceRef.current.map= new window.kakao.maps.Map(container, options);

        // 마커 이미지 설정 (React에서 import한 로컬 이미지 사용)
        const imageSize = new window.kakao.maps.Size(42, 42);  // 이미지 크기 설정
        const imageOption = { offset: new window.kakao.maps.Point(21, 42) };  // 이미지 기준점 설정
        const markerImage = new window.kakao.maps.MarkerImage(CustomMarkerImage, imageSize, imageOption);
  
        // 지도를 클릭한 위치에 표출할 마커입니다
        var marker = new window.kakao.maps.Marker({ 
          // 지도 중심좌표에 마커를 생성합니다 
          position: instanceRef.current.map.getCenter(),
          image: markerImage,  // 커스텀 마커 이미지 사용
        }); 

        console.log('marker : ', marker);
        // 지도에 마커를 표시합니다
        marker.setMap(instanceRef.current.map);

        if (isAndroid) {
          // CustomOverlay 생성 - HTML 요소를 삽입하여 접근성 지원
          const overlayContent = document.createElement('div');
          overlayContent.setAttribute('aria-label', '이미지');
          overlayContent.style.position = 'relative';
          overlayContent.style.width = '42px';
          overlayContent.style.height = '42px';
          overlayContent.style.backgroundColor = 'transparent';
          overlayContent.style.zIndex = '10'; // 지도 위에 표시

          // 클릭 이벤트 추가
          overlayContent.addEventListener('click', () => {
            console.log('마커 클릭됨');
          });

          // CustomOverlay 객체 생성 및 설정
          const customOverlay = new window.kakao.maps.CustomOverlay({
            map: instanceRef.current.map,
            position: marker.getPosition(),
            content: overlayContent,
            yAnchor: 1, // 위치 맞추기
          });

          // 지도 클릭 이벤트 추가
          window.kakao.maps.event.addListener(instanceRef.current.map, 'click', async function(mouseEvent) {
            const latlng = mouseEvent.latLng;
            marker.setPosition(latlng);
            customOverlay.setPosition(latlng); // 오버레이 위치도 마커 위치로 갱신
          });
        }

        console.log('window.kakao.maps.load x : ', x, ' y: ', y);
        const result = await getAddressInformation( Number(mapX || x) , Number(mapY || y));
        console.log('x :', x, ' y:', y, ' mapX :', mapX, 'result : ', result);
        const addressMain = (request !== 'modify' &&  hostAddressName) || searchAddressName || getAddressPriority(result[0]);
        const addressOnlyAddress = getAddressPriorityExcludeBuildingName(result[0]);
  
        setBottomAddressMain(addressMain);
        setBottomAddressSub(addressOnlyAddress);

        // departure 인 경우는 여기서 넣어줌.
        const originalAddressName = request === 'departure' ? addressOnlyAddress : undefined;

        /*
          map 의 설정에서만 전달하는 것으로 변경하려고 함.
        */
        setResponse({command: 'complete', response: request, lat: Number(y), lng: Number(x), addressName : addressMain, originalAddressName: originalAddressName});
        // console.log('[crossFrame][init] ');
        // instanceRef.current.crossFrame.emit('iframe_to_parent', { lat: Number(y), lng: Number(x), addressMain }, function(err, result) {
        //   console.log('[crossFrame][init] iframe_to_parent callback', 'err:', err, 'result:', result);
        // });

        window.kakao.maps.event.addListener(instanceRef.current.map, 'click', async function(mouseEvent) {
          const latlng = mouseEvent.latLng;
          const lat = latlng.getLat();
          const lng = latlng.getLng();
  
          // 마커 위치를 클릭한 위치로 옮깁니다
          console.log('setPotision : ', latlng);
          marker.setPosition(latlng);

          setMove(true);

          if (hostAddressName) {
            setHostAddressName(null);
          }

          // 불필요했던 부분으로 기억함.
          // 부모 창에 데이터 전달
          // if (window.opener && !window.opener.closed) {
          //   window.opener.postMessage({ lat, lng }, '*');
  
            //진입하자마자 경로를 넘기던 부분은 ux 를 변경하면서 삭제함 - 2024/09/04
            // instanceRef.current.crossFrame.emit('iframe_to_parent', { lat, lng }, function(err, result) {
            //   console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
            // });
          // }


          setMapX(Number(lng));
          setMapY(Number(lat));
  
          const result = await getAddressInformation( lng , lat);
          console.log('x :', x, ' y:', y, ' mapX :', mapX, 'result : ', result);
          const addressName = getAddressPriority(result[0]);
          const addressOnlyAddress = getAddressPriorityExcludeBuildingName(result[0]);
  
          setBottomAddressMain(addressName);
          setBottomAddressSub(addressOnlyAddress);
  
          console.log({ lat, lng, addressName });

          // departure 인 경우는 여기서 넣어줌.
          const originalAddressName = request === 'departure' ? addressOnlyAddress : undefined;
  
          /*
          map 의 설정에서만 전달하는 것으로 변경하려고 함.
          */
          setResponse({command: 'complete', response: request, lat, lng, addressName, originalAddressName: originalAddressName });
          // console.log('[crossFrame][click] ');
          // instanceRef.current.crossFrame.emit('iframe_to_parent', { lat, lng, addressName }, function(err, result) {
          //   console.log('[crossFrame][click] iframe_to_parent callback', 'err:', err, 'result:', result);
          // });
  
          // 현재 창 닫기
          // window.close();
        });
      // }
      
    });
  }, [request, searchAddressName, setMapX, setMapY, x, y]);

  const handleExit = () => {
    console.log("handleExit");
    const responseJson = {command: 'exit'};
    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });
  };

  const handleBack = () => {
    console.log("handleBack");
    setMapX(null);
    setMapY(null);
    navigate('/', { replace: true });
  };

  const handleOpenDialog = (title, content, confirmText = '확인') => {
    setDialogProps({
      open: true,
      title,
      content,
      confirmText
    });
  };

  const handleCloseDialog = () => {
    setDialogProps({
      ...dialogProps,
      open: false
    });
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden', backgroundColor:'white' }}>
      <BackButton exitCallback={handleBack} customIcon={<img src={SearchIcon} alt='검색' />}/>
      {/* 지도의 높이를 하단 컨테이너 높이를 제외한 영역으로 설정 */}
      <div id="map" style={{ width: '100vw', height: 'calc(100vh - 160px)', position: 'absolute', top: 0, left: 0 }}></div>
      {/* 하단 컨테이너 */}
      <Box
        sx={{
          marginBottom: '5px',
          marginTop:'5px',
          boxSizing: 'border-box',
          position: 'absolute',
          bottom: 0,
          width: 'calc(100%)',
          backgroundColor: 'white',
          padding: '16px',
          boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
          border: 'none',
          borderTopLeftRadius: '16px',  // 좌측 상단 모서리 둥글기
          borderTopRightRadius: '16px', // 우측 상단 모서리 둥글기
          // height: 'auto',  // 기본적으로 하단 박스 높이를 100px로 설정
          // '@media (min-height: 600px)': {
          //   height: '150px', // 화면이 큰 경우 높이를 150px로 설정
          // },
          // '@media (max-height: 600px)': {
          //   height: '85px',  // 세로 화면 등 작은 화면에서는 높이를 80px로 설정
          // },
          height: 'auto',
          maxHeight: '160px', // 최대 높이 설정
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 2
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',  // 텍스트가 넘칠 경우 말줄임 표시
            whiteSpace: 'nowrap',      // 텍스트를 한 줄로 유지
            width: '100%',
        }}>
          <Typography variant="h6" gutterBottom noWrap>{bottomAddressMain}</Typography>
          {
            bottomAddressMain !== bottomAddressSub && 
            <Typography variant="body2" color="textSecondary" noWrap>{bottomAddressSub}</Typography>
          }
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableRipple
          sx={{
            marginTop: '16px',
            backgroundColor: '#23778e',  // 원하는 배경색
            borderRadius: '12px',  // 버튼의 모서리 둥글기
            height: '48px',  // 버튼 높이
            '&:hover': {
              backgroundColor: '#1b5a6b',  // 호버 시 배경색
            },
          }}
          onClick={sendCrossFrameEvent}
        >
          { request === 'favorite' || request === 'modify' ? '즐겨 찾는 장소로 설정' : '출발지로 설정'}
        </Button>
      </Box>
      <AndroidToast />
      <CustomDialog
        open={dialogProps.open}
        onClose={handleCloseDialog}
        title={dialogProps.title}
        content={dialogProps.content}
        confirmText={dialogProps.confirmText}
      />
    </div>
  );
}

export default MapApp;